<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="rgb(29 77 78)"
    flat
    height="65"
    style="color: white;"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="drawer">
        mdi-arrow-left
      </v-icon>
      
      <v-icon v-else>
        mdi-arrow-right
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />



    <div class="mx-3" />
    Bem vindo, {{this.user}}! Seu último acesso foi em {{this.data}}
    
    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/register">
      <v-icon class="white--text">mdi-key</v-icon>
    </v-btn>


    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="login">
      <v-icon class="white--text">mdi-logout</v-icon>
    </v-btn>


  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      notifications: [
      
      ],
      user:'',
      data: '',
    }),
   
    computed: {
      ...mapState(['drawer']),
    },

    mounted() {
     this.user = JSON.parse(sessionStorage.getItem('user')).nome;
     this.data = JSON.parse(sessionStorage.getItem('user')).acesso_previous;
    },

    methods: {
      
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

     

      logout(){
          sessionStorage.removeItem('user')
          to: login;
      },


    },



  }
</script>
